import './App.css';
import Logo from './assets/logo.png'

function App() {
  return (
    <div className='h-[100vh] w-full flex flex-col justify-center items-center overflow-hidden bg-black'>
      <div className='flex justify-center items-center'>
        <div>
          <img src={Logo} className='w-[300px] lg:w-[600px]' />
        </div>

      </div>
      <div className='text-white font-bold text-[35px] lg:text-[50px] uppercase'>
        Çok Yakında
      </div>
      <div className=' absolute bottom-0 mx-auto text-center'>
        <div className=' uppercase text-white font-bold text-[25px] lg:text-[35px]'>
          <a href='https://locgroup.com.tr' target='_blank'>
            Loc Group Bilişim
          </a>
        </div>
        <div className=' uppercase text-white font-bold text-[25px] lg:text-[35px]'>
          0242 922 2022
        </div>
      </div>
    </div >
  );
}

export default App;
